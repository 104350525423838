<div class="g-container">
  <div class="sidenav">
    <div class="p-2 h-100">
      <div class="topics">
        <span class="px-2">Input</span>
      </div>
      <div class="side-body">
        <div class="centered-content">
          <div class="">
            <div id="pdf-container"></div>
            <textarea [(ngModel)]="extractedData" class="chat-textarea" autocomplete="off"
              placeholder="Please write here"></textarea>
          </div>
        </div>
      </div>
      <div class="chat-footer">
        <div class="chat-cont2">
          <div class="attachment-button">
            <button matTooltip="upload" mat-mini-fab class="send-button" (click)="openPopup()">
              <mat-icon matSuffix>file_upload</mat-icon>
            </button>
          </div>

          <div class="button-group">
            <button mat-raised-button class="copy-btn ml-2 mx-2" [ngClass]="{'copy-btn1' : extractedData !== '' }" [disabled]="extractedData == ''" (click)="onReview()">
              <mat-icon>check</mat-icon> Review
            </button>
            <button mat-raised-button class="cancel-btn ml-2 mx-2" [ngClass]="{'cancel-btn1' : extractedData !== '' }" [disabled]="extractedData == ''" (click)="onClear()">
              <mat-icon>clear</mat-icon> Clear
            </button>
          </div>
          
        </div>
      </div>
    </div>
  </div>

  <div class="main-container">
    <div class="p-2 h-100">
      <div class="topics">
        <span class="output-wrapper px-2">Output </span>
      </div>

      <div class="item-body" [ngClass]="{'n-item-body': !dataSource}">
        <div *ngIf="!dataSource"  class="no-content">
          <img src="assets/images/review.svg"  class="upload-image" />
          <label class="pt-4">Please Enter or Upload Requirement</label>
      </div>

      <ng-container *ngIf="dataSource" >
        <div class="chart-card">
          <div class="card">
            <div class="text-center pt-2">OverAll Score</div>
            <div class="chart" id="overallscore">
              <apx-chart [series]="overallscoreoptions.series" [chart]="overallscoreoptions.chart"
                [plotOptions]="overallscoreoptions.plotOptions" [labels]="overallscoreoptions.labels"
                [stroke]="overallscoreoptions.stroke" [fill]="overallscoreoptions.fill"></apx-chart>
            </div>
          </div>
          <div class="card">
            <div class="text-center pt-2">Ratings</div>
            <div class="chart" id="overAllpercentage">
              <apx-chart [series]="overAllpercentageoptions.series" [chart]="overAllpercentageoptions.chart"
                [dataLabels]="overAllpercentageoptions.dataLabels" [plotOptions]="overAllpercentageoptions.plotOptions"
                [xaxis]="overAllpercentageoptions.xaxis" [stroke]="overAllpercentageoptions.stroke"
                [fill]="overAllpercentageoptions.fill" [title]="overAllpercentageoptions.title"
                [tooltip]="overAllpercentageoptions.tooltip" ></apx-chart>
            </div>
          </div>
        </div>

        <div class="chart-card1">
          <div class="card1">
            <div class="heading">
              <span class="output-wrapper px-2" *ngIf="isCompare"><mat-icon (click)="openComparison()">compare</mat-icon> </span>
            </div>
            <ng-container class="highlight-conatins">
              <p *ngIf="dataSource?.user_content" class="word-content m-3" #content
                [innerHTML]="highlightWords(dataSource?.user_content)"></p>
            </ng-container>
          </div>
        </div>
      </ng-container>

    </div>



      <div class="chat-footer" *ngIf="dataSource">
        <div class="chat-cont1">
          <button mat-raised-button class="copy-btn ml-2 mx-2" (click)="onRevert()" *ngIf="isCompare">
            <mat-icon>refresh</mat-icon> Revert All
          </button>
          <button mat-raised-button class="copy-btn ml-2 mx-2" (click)="replaceAllWithAlternatives()">
            <mat-icon>find_replace</mat-icon> Change All
          </button>
          <button mat-raised-button class="copy-btn ml-2 mx-2" (click)="copyText()">
            <mat-icon>file_copy</mat-icon> Copy Text
          </button>
          <button mat-raised-button class="cancel-btn ml-2 mx-2" (click)="onClear()">
            <mat-icon>clear_all</mat-icon> Clear
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<div *ngIf="isLoading" class="loader-container">
  <div class="circles">
    <div
      *ngFor="let color of ['primary', 'primary', 'primary', 'primary', 'alternate', 'alternate', 'alternate', 'alternate']"
      [ngClass]="color"></div>
  </div>
  <div class="loading-text">{{ currentText }}</div>
</div>