

export const environment={
  production: false,
  url:"https://teratics-api-dev-5i2n5uvnoa-el.a.run.app/"
  // url:"http://192.168.0.124:8000/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
