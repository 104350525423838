<div class="p-4 head">
    <div fxLayout="row pt-2" fxLayoutAlign="space-between">
        <span class="text-bold f"><b> Comparison</b></span>
        <mat-icon (click)="onClose()">close</mat-icon>
    </div>
    <div class="item-body">
        <div class="chart-card">
            <div class="or-card">
                <div class="topics">
                    <span class="output-wrapper px-2">Original</span>
                </div>
                <div class="source-content" #originalScroll (scroll)="syncScroll(originalScroll, updatedScroll)">
                    <p [innerHTML]="originalDataSource"></p>
                </div>
            </div>
            <div class="or-card">
                <div class="topics">
                    <span class="output-wrapper px-2">Updated</span>
                </div>
                <div class="source-content" #updatedScroll (scroll)="syncScroll(updatedScroll, originalScroll)">
                    <p [innerHTML]="changedDataSource"></p>
                </div>
            </div>
        </div>
    </div>
</div>
