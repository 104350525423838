import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeaturesRoutingModule } from './features-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GenerateRequirementsComponent } from './generate-requirements/generate-requirements.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from '../shared/shared.module';
import { ReviewRequirementsComponent } from './review-requirements/review-requirements.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InfoDashboardComponent } from './info-dashboard/info-dashboard.component';
import { ClassifyRequirementsComponent } from './classify-requirements/classify-requirements.component';
import { SplitContentPipe } from 'src/app/core/utils/pipe/splitContent.pipe';
import { RouterModule } from '@angular/router';
import { BoldFormatPipe } from 'src/app/core/utils/pipe/boldformat.pipe';
import { HeaderComponent } from 'src/app/layout/header/header.component';


@NgModule({
  declarations: [
    DashboardComponent,
    GenerateRequirementsComponent,
    ReviewRequirementsComponent,
    InfoDashboardComponent,
    ClassifyRequirementsComponent,
    SplitContentPipe,
    BoldFormatPipe,    
  ],
  imports: [
    CommonModule,
    FeaturesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    SharedModule,
    NgApexchartsModule,
    RouterModule

  ]
})
export class FeaturesModule { }
