import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of, map, catchError } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { HTTPSTATUS, PAGEACCESS, HTTPSTATUSCODE } from "../../utils/enum/enum";
import { ToastrService } from "ngx-toastr";
@Injectable()
export class InterceptorService implements HttpInterceptor {


  constructor(private authService: AuthService, private router: Router, private _dialogRef: MatDialog,private toastr: ToastrService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if(event.type == 4){
            const session_id:any = event.headers.get('session_id');
            const authorization:any = event.headers.get('authorization');
            let oldToken = sessionStorage.getItem('authorization');
            if(oldToken && oldToken != null && authorization != null && oldToken != authorization){
                sessionStorage.setItem('authorization', authorization);
            }
            if(session_id && session_id != null){
              sessionStorage.setItem('session_id', session_id);
            }
            if(authorization != null ){
              sessionStorage.setItem('authorization', authorization);
            }
           
          }
          if (event?.status == HTTPSTATUSCODE.Created || event?.status == HTTPSTATUSCODE.Success) {
            // this.toastr.success(HTTPSTATUS.Success, event?.body?.message);
          } else if (event?.status == HTTPSTATUSCODE.InternalServerError && event?.body?.message == HTTPSTATUS.Failure) {
            this.toastr.error(HTTPSTATUS.Failure, event?.body?.message);
          }
        }
        return event;
      }),
        catchError(err => {
          console.log(err.headers.get('authorization'));
          const session_id:any = err.headers.get('session_id');
          const authorization:any = err.headers.get('authorization');
          if(authorization){
            let oldToken = sessionStorage.getItem('authorization');
            if(oldToken && oldToken != null && authorization != null && oldToken != authorization){
                sessionStorage.setItem('authorization', authorization);
            }
            if(session_id && session_id != null){
              sessionStorage.setItem('session_id', session_id);
            }
            if(authorization != null ){
              sessionStorage.setItem('authorization', authorization);
            }
          }            
          const error = err.error.detail || err.error.message;
          if (err.status === HTTPSTATUSCODE.UnAuthorized) {
            sessionStorage.clear();
          this._dialogRef.closeAll();
          this.authService.currentUserSub(null);
          this.authService.setSessionExpired(true);
          this.router.navigate(['/']);
          }
          if(err.status === HTTPSTATUSCODE.UnSupported){
            this.toastr.error(HTTPSTATUS.Failure, error);
          }
          return throwError(error);
        })
  )}

}
