<mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="end center">
    <mat-icon (click)="onClose()">close</mat-icon>
  </div>

  <div class="upload-files-container">
    <label class="drag-file-area" for="fileInput">
      <mat-icon class="upload-icon">cloud_upload</mat-icon>
      <input id="fileInput" class="d-none default-file-input" type="file" #fileInput required [multiple]="uploadConfig?.isMultiple"
      name="fileUpload" [accept]="uploadConfig?.accept" (change)="uploadFileNew($event)" />
      <label for="fileInput" class="browse-files-text">Browse Files</label>
    </label>
    <!-- <label *ngIf="files.length == 0" class="sample-file" (click)="onUploadSampleFile()">Sample file</label> -->
    <span class="cannot-upload-message" *ngIf="isError">
       Please select a file
      <mat-icon class="error-icon" (click)="onCloseError()">close</mat-icon>
    </span>

    <div class="file-block w-100" *ngFor="let item of files; let index = index">
      <div class="file-info">
        <span class="file-name">{{item.fName}}</span> | <span class="file-size">{{item.fSize}}</span>
        <span class="material-icons remove-file-icon" (click)="removeSelectedFile()">delete</span>
      </div>
    </div>

    <button type="button" class="upload-button" (click)="onUpload()">Upload</button>
  </div>
</mat-dialog-content>