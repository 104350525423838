<mat-toolbar class="header">
  <a routerLink=""> <img src="assets/images/InnoBoon-Logo.png" class="logo" alt="Innobbon" /></a>
  <span class="example-spacer"></span>
</mat-toolbar>

<div class="row p-3">
  <div *ngFor="let section of sections" class="col-md-4">
    <div class="card">
      <div class="sticky-header">{{ section.title }}</div>
      <div class="scrollable-list">
        <div *ngFor="let item of section.items" class="list-item">
          <div class="card-2" (click)="redirectToPage(item)">
            <div class="item-header">{{ item.header }}</div>
            <div class="item-content">
              <div *ngFor="let content of item.content.split('. '); let isfirst = first">
                <p *ngIf="isfirst" [innerHTML]="content | boldFormat"></p>
                <ul *ngIf="!isfirst">
                  <li *ngFor="let sentence of content.split('. ')" [innerHTML]="sentence | boldFormat"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

