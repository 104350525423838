import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'boldFormat'
  })
  export class BoldFormatPipe implements PipeTransform {
    transform(content: string): string {
      let wordsToBold = [
        'CAIP', 'Discovery Bot', "Knowledge Graph" ,'Code GenX','Pandas AI - Analytic Bot:',
       'RECRUIT AI', "Profile matching with JD:", "Candidate Engagement:", "Carol, Your DEI Assistant:",
        'JD BIAS', "Resume Masking:", "Benchmarking:","JD :", "Classify Requirements:",
        'DILIGENCE GPT',"Anomaly Detection:",'Financial Benchmark:',"Functional Benchmark:","Census Annotation:",
         'REQUIREMENT ENGINEERING','P&L Annotation:', 'Generate Requirements:', 'Review Requirements:', 
          'CODE GEN GPT','GAI', "Group Requirements: " , "Generate Verification Criteria:" , 
          "Summarize Requirements and Q&A: " , "Lead Scoring:" , "Product Recommendation:", 
          "Time Series Forecasting:", "Sales Forecasting: ", "Classification of Data:",
          "Teratic AI -" , "Software-Defined Vehicle:", "AI Analytics:", "Generative AI:" , 
          "Digital Services:" , "Vehicle Data Sharing:" , "Connected Mobility:" , 
          "BOTSEYE -" , "Face Mask Detection:" , "Crowd and Queue Management:" , 
          "Person Counting:" , "Social Distancing Violation:" , "Retail Heatmap & Business Intelligence:",
          "Forecasting -" , "Field Force Management -" , "Deduction link ->", "Neo track -" , "Neo Wings -",
          "NeoTag -", 
        ];
      wordsToBold.forEach(word => {
        let regex = new RegExp(word, 'g');
        content = content.replace(regex, `<strong>${word}</strong>`);
      });
      return content;
    }
  }