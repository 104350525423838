

import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  userName:any;

  @Output() emitSettingInfo = new EventEmitter();
  constructor(private observer: BreakpointObserver, private router: Router,
    private dialog: MatDialog,private cdRef:ChangeDetectorRef,private toastr:ToastrService ,
    private authservice: AuthService,private apiservice : ApiService) { }

  ngOnInit() {
    const user:any = window.sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(user).first_name + ' ' + JSON.parse(user).last_name;
  }


  logout() {
    this.apiservice.apiRequestGet('auth/sign_out').subscribe((data:any) => {
      if (data) {
        sessionStorage.clear();
        this.authservice.currentUserSub(null);
        this.router.navigate(['/']);
      }
    });
  }






 
  
}
