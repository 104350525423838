import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ApiService } from 'src/app/core/services/api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-dashboard',
  templateUrl: './info-dashboard.component.html',
  styleUrls: ['./info-dashboard.component.scss']
})
export class InfoDashboardComponent implements OnInit {
  newSections: any;
  constructor(private router: Router) {
  }

  ngOnInit() {
    this.newSections = this.sections.map(section => {
      return {
        title: section.title,
        items: section.items.map(item => {
          const sentences = item.content.split('.').map(sentence => sentence.trim());
          const firstSentence = sentences.shift();
          const otherSentences = sentences.map(sentence => ({
            content: sentence,
            hasBullet: sentence.length > 0
          }));
          return [
            { content: firstSentence, hasBullet: false },
            ...otherSentences
          ];
        }).flat()
      };
    });

    console.log(this.newSections);

  }

  sections = [
    {
      title: 'GEN AI',
      items: [
        { header: 'CAIP', content: 'Demonstrates our core Generative AI capabilities. Discovery Bot: Autonomously uncovers insights in data. Knowledge Graph: Interconnects data in graph-structure. Code GenX: AI-driven code generation and optimization. Pandas AI - Analytic Bot: Advanced data processing and analytics.' },
        { header: 'Recuruit AI', content: 'Streamlining and enhancing the recruitment process using artificial intelligence. Profile matching with JD: Analyse and shortlist resume(s) with matching JDs Questionnaire based on JD(Job Description-focused queries).' },
        { header: 'DEI Checker', content: 'Job Description Bias Analysis. Resume Masking: Anonymizes Candidate Resumes. Candidate Engagement: Enhances Applicant Interaction. Carol, Your DEI Assistant: Diversity, Equity, Inclusion Support. Benchmarking: Standards and Performance Comparison.' },
        { header: 'Diligence GPT', content: "Integration with any PE firm’s knowledge base, private equity pattern recognition and its support for hypothesis formulation, data visualization, and tailored content for better solutions Trend Analysis: Identifies data patterns over time. Anomaly Detection: Detects irregular data points. Financial Benchmark: Compares financial performance metrics. Functional Benchmark: Evaluates operational effectiveness. Census Annotation: Labels and interprets census data. P&L Annotation: Analyzes Profit and Loss statements'"},
        { header: 'Requirement Engineering', content: "Automate & Enhance steps in requirements engineering process. Generate Requirements: Creates formal requirement specifications.Review Requirements: Improves requirement clarity and correctness. Classify Requirements: Categorizes requirements systematically. Group Requirements: Organizes similar requirements together. Generate Verification Criteria: Defines criteria for requirement validation. Summarize Requirements and Q&A: Briefs requirements and answers queries."},
        { header: 'Code Gen GPT', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' }
      ]
    },
    {
      title: 'AI/ML - Analytics',
      items: [
        { header: 'GAI', content: "Leveraging AI for predictive analytics and data analysis across various domains. Lead Scoring: Prioritizes potential customer leads. Product Recommendation: Suggests relevant products. Time Series Forecasting: Predicts future data trends. Anomaly Detection: Identifies unusual data patterns. Sales Forecasting: Projects future sales volumes. Classification of Data: Categorizes data systematically."},
        { header: 'Vehicle Analytics (Teractics.ai)', content: 'Expertise in Automotive Software, IoT, Generative AI, Machine Learning, Big Data Analytics, Cloud Services and end-to-end IT solutions to offer services tailored for the vehicle manufacturers. Software-Defined Vehicle: Vehicle functionality controlled by software. AI Analytics: Advanced data analysis using AI. Generative AI: AI that creates new content. Digital Services: Online-based service offerings. Vehicle Data Sharing: Exchange of vehicle-generated data. Connected Mobility: Integrated, networked transportation solutions.' },
        { header: 'Video Analytics (botseye.com)', content: 'AI Based Data, Video analytics solution that makes places safe, smart and business more profitable Motion Detection: Identifies and tracks movement. Face Mask Detection: Detects mask usage on faces. Crowd and Queue Management: Manages crowd flow and queues. Person Counting: Counts individuals in an area. Social Distancing Violation: Monitors social distancing compliance. Retail Heatmap & Business Intelligence: Analyzes retail space and customer behavior.' },
        { header: 'Forecasting', content: "Inventory and sales forecasting'" },
      ]
    },
    {
      title: 'Non AI Products',
      items: [
        // { header: 'Field Force Management', content: 'Focus on Centralized work order management, employee management, and end user application' },
        { header: 'Deductions Management', content: 'DeductionsLink is a web based platform and a Cloud Solution ecosystem. This platform facilitates management of settlements between Manufacturer and Retailers/ Distributors.' },
        { header: 'NeoTrack', content: "AI based Tracking Solutions for School Bus and Employees"},
        { header: 'NeoWings', content: 'Delivery Management, Route Optimization & Logistics Solution' },
        { header: 'NeoTag', content: "GPS/RFID/Bluetooth based tracking solution for kids & movable assets'"},
        { header: 'NeoFleet', content: 'Fleet Management and optimazation Solution' },
      ]
    },
  ];


  
  redirectToPage(item) {
    const urlMap = {
      "CAIP": 'https://caip-web-app-l3v6ub3yaa-as.a.run.app/',
      "Recuruit AI" : '',
      "DEI Checker" :'http://dei-auditor.innoboon.com/?type=Dasboard' ,
      "Diligence GPT" : 'https://qa.diligencegpt.ai/',
      "Requirement Engineering": 'https://teratics-web-dev-5i2n5uvnoa-as.a.run.app/',
      "Code Gen GPT" : '',

      "GAI" : 'https://gada-web-a7s2p6zxjq-as.a.run.app/login',
      "Vehicle Analytics (Teractics.ai)": 'https://teratics.com',
      "Video Analytics (botseye.com)" :"https://botseye.com",
      "Forecasting" : '',

      "Field Force Management" : '',
      "Deductions Management" : '',
      "NeoTrack" : 'https://neotrack.ai/',
      "NeoWings" : 'http://neowings.in/',
      "NeoTag" : 'http://www.neotag.in/',
      "NeoFleet" : 'https://www.neofleet.in/'
      

    };
  
    const url = urlMap[item.header];
    if (url) {
      this.redirectToExternalUrl(url);
    }
  }
  
  redirectToExternalUrl(url) {
    window.location.href = url;
  }

 
  
}
