<section class="p-4">
    <div class="row" *ngIf="dataSource.alternativeWords.length > 0">
        <div fxLayout="row" fxLayoutAlign="space-between">
            <h3 class="mb-1"><b>Alternative words</b></h3>
            <mat-icon (click)="onClose()">close</mat-icon>
        </div>

        <div class="custom-chip-container pt-3">
            <div class="custom-chip"  [class.selected]="selectedChip === item" *ngFor="let item of dataSource.alternativeWords" (click)="onSelectChips(item)">
                {{ item }}
            </div>
        </div>
    </div>

    <div class="button-container pt-3">
        <button mat-raised-button class="custom-button ml-2 mx-2" [ngClass]="{'disable': !selectedChip }" (click)="onReplace()">
            Replace
        </button>
    </div>
</section>