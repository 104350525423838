<div class="g-container">
    <div class="sidenav">
        <div class="p-2 h-100">
            <div class="topics">
                <span class="px-2">Input</span>
            </div>
            <div class="side-body">
                <div class="centered-content">
                    <div class="">
                        <div id="pdf-container"></div>
                        <textarea [(ngModel)]="extractedData" class="chat-textarea" autocomplete="off"
                            placeholder="Please write here"></textarea>
                    </div>
                </div>
            </div>
            <div class="chat-footer">
                <div class="chat-cont2">
                    <div class="attachment-button">
                        <button matTooltip="upload" mat-mini-fab class="send-button" (click)="openPopup()">
                            <mat-icon matSuffix>file_upload</mat-icon>
                        </button>
                    </div>

                    <div class="button-group">
                        <button mat-raised-button class="copy-btn ml-2 mx-2" (click)="onReview()">
                            classify
                        </button>
                        <button mat-raised-button class="cancel-btn ml-2 mx-2" (click)="onClear()">
                            Clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-container">
        <div class="p-2 h-100">
            <div class="topics">
                <span class="output-wrapper px-2">Output </span>
                 <i class="fa fa-table icon mx-2"></i>
               <i class="fa fa-sitemap icon mx-2"></i>
            </div>
           

            <!-- <div class="item-body" [ngClass]="{'n-item-body': !dataSource}">
          <div *ngIf="!dataSource"  class="no-content">
            <img src="assets/images/gen-req-emp.svg"  class="upload-image" />
            <label class="pt-4">Please Enter Requirement</label>
        </div> -->
                <ng-container>
                    <div class="chart-card2">
                      <div class="card2" *ngFor="let requirement of softwareRequirements">
                        <div class="headings">
                          <h3>{{ requirement.title }}</h3>
                        </div>
                        <p>{{ requirement.description }}</p>
                      </div>
                    </div>
                  </ng-container>
                  

            <div class="chat-footer" *ngIf="dataSource">
                <div class="chat-cont1">
                    <button mat-raised-button class="copy-btn ml-2 mx-2" (click)="copyText()">
                        Copy Text
                    </button>
                    <button mat-raised-button class="cancel-btn ml-2 mx-2" (click)="onClear()">
                        Cancel
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="isLoading" class="loader-container">
    <div class="circles">
        <div *ngFor="let color of ['primary', 'primary', 'primary', 'primary', 'alternate', 'alternate', 'alternate', 'alternate']"
            [ngClass]="color"></div>
    </div>
    <div class="loading-text">{{ currentText }}</div>
</div>