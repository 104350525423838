import { ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChartComponent } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { overallscore, overAllpercentage } from 'src/app/core/utils/data/chart-data';
import { Ireplace, IalterDetails } from 'src/app/core/utils/interface/shared.interface';
import { ReviewAlternativeComponent } from '../../shared/review-alternative/review-alternative.component';
import { ReviewComparisionComponent } from '../../shared/review-comparision/review-comparision.component';
import { UploadComponent } from '../../shared/upload/upload.component';
export interface SoftwareRequirement {
  title: string;
  description: string;
}
@Component({
  selector: 'app-classify-requirements',
  templateUrl: './classify-requirements.component.html',
  styleUrls: ['./classify-requirements.component.scss']
})
export class ClassifyRequirementsComponent {

  extractedData: string;
  dataSource: any = null;
  updatedContent: any = null;
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  isCompare: boolean = false;
  @ViewChild('content') content: ElementRef;

  @ViewChild("overallscore") overallscore: ChartComponent;
  @ViewChild("overAllpercentage") overAllpercentage: ChartComponent;
  public overallscoreoptions: Partial<any>;
  public overAllpercentageoptions: Partial<any>;
  pdfData: any;

  softwareRequirements: SoftwareRequirement[] = [
    {
      title: 'Increased Efficiency',
      description: 'Classifying software requirements helps in identifying gaps and ensuring all necessary functionalities are included, leading to a more efficient development process.'
    },
    {
      title: 'Effective Communication',
      description: 'A well-defined categorization of software requirements enables effective communication between developers and stakeholders, ensuring everyone is on the same page.'
    },
    {
      title: 'Alignment with User Needs',
      description: 'Organized software requirements facilitate the development of a system that truly addresses the needs and expectations of users.'
    }
  ];
 

  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService, private renderer: Renderer2,
    private cdr: ChangeDetectorRef,) {
    this.overallscoreoptions = overallscore;
    this.overAllpercentageoptions = overAllpercentage;
  }


  ngOnInit(): void {

  }


  openPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.extractedData = '';
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait", "Almost finished"];
    this.isLoading = true;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        this.extractedData = data.output;
        this.isLoading = false;
        this.toastr.success('Success !', 'Data uploaded successfully.');
      }
    },
      err => {
        this.isLoading = false;
        this.toastr.error('Error data upload Failed.');
      }
    );

  }


  openAlterDetailsPopup(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.position = { bottom: '0' },
    dialogConfig.enterAnimationDuration = 2;
    dialogConfig.exitAnimationDuration = 700;
    dialogConfig.data = this.onTransForm(data);
    this.dialog.open(ReviewAlternativeComponent, dialogConfig).afterClosed().subscribe(res => {
      this.onGet(res);

    })
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('alter-content')) {
      const word = target.innerText;
      if (word) {
        const data = this.dataSource?.recommended_modifications?.find((x) => x.modification_point == word);
        this.openAlterDetailsPopup(data);
      }
    }
  }


  highlightWords(text: string): string {
    if (this.dataSource?.recommended_modifications && this.dataSource?.recommended_modifications?.length > 0) {
      this.dataSource?.recommended_modifications?.forEach(obj => {
        const regex = new RegExp(obj.modification_point, 'g');
        text = text.replace(regex, `<span class="alter-content ${obj?.impact_level}">${obj?.modification_point}</span>`);
      });
    }
    text = text.replace(/\n/g, '<br>');
    return text;
  }


  onReview() {
    this.texts = ["Reviewing data", "Collecting Informations", "Please wait", "Almost finished"];
    this.isLoading = true;
    this.startTextAnimation();
    this.apiService.apiRequestPost('ai_services/review_requirements', null, { 'text': this.extractedData }).subscribe((data: any) => {
      if (data) {
        this.dataSource = data.output;
        overallscore.series = [this.dataSource.overall_score];
        overAllpercentage.series = [
          { data: [...this.dataSource.item_ratings] }
        ];
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    },
      err => {
        this.isLoading = false;
        this.toastr.error('Error data upload Failed.');
      }
    );
  }




  onGet(data: any) {
    let set: Ireplace = {
      user_input: this.dataSource.user_content,
      modifications: [
        {
          original_text: data.originalWord,
          suggested_text: data.replaceWord,
        },
      ],
      recommended_modifications: this.dataSource.recommended_modifications,
    };

    this.isLoading = true;
    this.apiService.apiRequestPost('/entity_service/change-alternatives', '', set).subscribe((data: any) => {
      if (data) {
        this.dataSource["user_content"] = data.modified_input;
        this.dataSource["recommended_modifications"] = data.recommended_modifications;
        this.isCompare = true;
        this.isLoading = false;
        this.toastr.success('Success !', 'Data uploaded successfully.');
      }
    },
      err => {
        this.toastr.error('Error data upload Failed.');
        this.isLoading = false;
      }
    );
  }



  onTransForm(data: any): any {
    let set = <IalterDetails>{};
    set.modification_point = data?.modification_point,
    set.alternativeWords = data?.alternatives || []
    return set;
  }


  onClear() {
    this.extractedData = '';
    this.dataSource = null;
  }



  startTextAnimation() {
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 5000);
  }


  changeText() {
    this.currentText = this.texts[this.index];
    this.index = (this.index + 1) % this.texts.length;
    if (this.index === 0) {
      clearInterval(this.intervalId);
    }
  }


  copyText() {
    let textToCopy = '';
    if (
      typeof this.dataSource === 'object' &&
      this.dataSource !== null &&
      this.dataSource.hasOwnProperty('user_content') &&
      typeof this.dataSource.user_content === 'string'
    ) {
      textToCopy = this.dataSource.user_content;
    } else {
      console.error('File content not found or has an incorrect type.');
      return;
    }
    textToCopy = textToCopy.trim();
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastr.success('Successfully File Content Copied.');
  }

  formatItem(item) {
    if (typeof item === 'object' && item !== null) {
      let formattedItem = '';
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          formattedItem += `${key}: ${item[key]}\n`;
        }
      }
      return formattedItem + '\n';
    } else {
      return `${item}\n\n`;
    }
  }
  


}
