import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environment/environment';
const BASE_URL = environment.url;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

 

  apiRequestGet(endPoint?: any, queryParam?: any): Observable<any> {
    let session_id: any = sessionStorage.getItem('session_id');
    let authorization: any = sessionStorage.getItem('authorization');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.get<any>(`${url}`, {
      headers: new HttpHeaders({
        session_id: session_id,
        authorization: 'Bearer ' + authorization
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error())
      })
    );
  }
  apiRequestPost(endPoint?:any,queryParam?:any,payload?:any): Observable<any> {
    let session_id:any = sessionStorage.getItem('session_id');
    let authorization:any = sessionStorage.getItem('authorization');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.post<any>(`${url}`, payload, {
      headers: new HttpHeaders({
        session_id: session_id,
        authorization: 'Bearer ' + authorization
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error())
      })
    );
  }
  apiRequestDelete(endPoint?:any,queryParam?:any,payload?:any): Observable<any> {
    let session_id:any = sessionStorage.getItem('session_id');
    let authorization:any = sessionStorage.getItem('authorization');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.delete<any>(`${url}`, {
      headers: new HttpHeaders({
        session_id: session_id,
        authorization: 'Bearer ' + authorization
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error())
      })
    );
  }

  convertTextToFormData(text: string): FormData {
    const formData = new FormData();
    formData.append('text', text);
    return formData;
  }

}
