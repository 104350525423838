import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GenerateRequirementsComponent } from './generate-requirements/generate-requirements.component';
import { ReviewRequirementsComponent } from './review-requirements/review-requirements.component';
import { InfoDashboardComponent } from './info-dashboard/info-dashboard.component';
import { ClassifyRequirementsComponent } from './classify-requirements/classify-requirements.component';

const routes: Routes = [
  { path: 'dashboard',component: DashboardComponent, },
  { path: 'gen-req',component:GenerateRequirementsComponent },
  {path: 'rev-req',component:ReviewRequirementsComponent},
  {path: 'info-dashboard',component: InfoDashboardComponent,},
  {path: 'class-req',component: ClassifyRequirementsComponent,}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeaturesRoutingModule { }
