import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IalterDetails } from 'src/app/core/utils/interface/shared.interface';
import { ReviewRequirementsComponent } from '../../features/review-requirements/review-requirements.component';

@Component({
  selector: 'app-review-alternative',
  templateUrl: './review-alternative.component.html',
  styleUrls: ['./review-alternative.component.scss']
})
export class ReviewAlternativeComponent {


  dataSource: IalterDetails;
  replacedWords : any = null;
  selectedChip: string | null = null;
  constructor(
    private digloagRef: MatDialogRef<ReviewRequirementsComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.dataSource = data;
  }

  ngOnInit(){

  }

  onSelectChips(selectedWord: string) {
    this.selectedChip = selectedWord;
    if (selectedWord) {
        this.replacedWords = {
            originalWord: this.dataSource.modification_point,
            replaceWord: selectedWord
        };
    }
} 

onReplace(){
  if(this.replacedWords){
    this.digloagRef.close(this.replacedWords);
  }
}

onClose(){
   this.digloagRef.close(null);
}

  
}
