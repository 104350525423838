<div class="g-container">
    <div class="sidenav">
        <div class="p-2 h-100">
            <div class="topics">
                <span class="px-2"> Input </span>
                <!-- <mat-icon matTooltip="Input" class="new-chat-icon">input</mat-icon> -->
            </div>
            <div class="side-body">
                <div class="centered-content">
                    <form [formGroup]="form">
                        <div class="">
                            <textarea formControlName="reqInput" class="chat-textarea" autocomplete="off"
                                placeholder="Please write here"></textarea>
                        </div>
                        <div class="pt-4">
                            <mat-checkbox formControlName="includeVerify" class="example-margin">Include Verification
                                Criteria</mat-checkbox>
                        </div>
                    
                        <div class="pt-4">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label> Output Format</mat-label>
                                <mat-select formControlName="userStory">
                                    <mat-option *ngFor="let data of userStory" [value]="data.value">{{data.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </form>

                </div>
            </div>
            <div class="chat-footer">
                <div class="chat-cont">
                    <button mat-raised-button [disabled]="!form.valid" (click)="onGenerate()" class="gen-btn w-100 ml-2 mx-2" [ngClass]="{'gen-btn1' : form.valid }">
                        <mat-icon>input</mat-icon> Generate
                    </button>
                </div>
            </div>

        </div>
    </div>

    <div class="main-container">
        <div class="p-2 h-100">
            <div class="topics">
                <span class="output-wrapper px-2">
                    Output
                </span>
                <mat-icon matTooltip="History" (click)="onHistory()">history</mat-icon>
            </div>  

            <div class="item-body mt-4" [ngClass]="{'n-item-body': data.length <= 0}">
                <div *ngIf="data.length <= 0"  class="no-content">
                    <img src="assets/images/gen-req-emp.svg"  class="upload-image" />
                    <label class="pt-4">Please Enter Requirement</label>
                </div>
                <div class="data-container" >
                    <ng-container *ngFor="let item of data">
                      <div class="data-item">
                        <div class="d-title-row">
                          <div  class="d-title">{{ item.name }}</div>
                        </div>
                        <div class="d-desc-row">
                          <div  class="d-desc">{{ item.value }}</div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
            </div>
            <div class="chat-footer" *ngIf="data.length > 0" >
                <div class="chat-cont1">
                    <button mat-raised-button class="copy-btn ml-2 mx-2" (click)="copyText()">
                        <mat-icon>file_copy</mat-icon>  Copy Text
                    </button>
                    <button mat-raised-button class="cancel-btn ml-2 mx-2" (click)="onClear()">
                        <mat-icon>clear</mat-icon>  Clear
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="historynav" *ngIf="historyNav">
        <div class="p-2 h-100">
            <div class="topics">
                <span class="px-2"> History </span>
                <mat-icon matTooltip="Close" class="new-chat-icon" (click)="onHistory()">close</mat-icon>
            </div>
            <div class="side-body">
                <div class="top mb-2">
                    <span>
                        <h4 class="clr text-start px-2">Today</h4>
                        <ng-container *ngFor="let topic of topics">
                            <button mat-button class="list-btn menu-button" style="margin: 12px 0;" (click)="onLoadHistory(topic.topic_id)">
                                <a class="nav-link topic-btn">
                                    <div class="topic-container">
                                        <mat-icon class="topic-icon">chat_bubble_outline</mat-icon>
                                        <div class="topic-text-container">
                                            <div class="text-ellipsis" [matTooltip]="topic.question">
                                                <span class="topic-question">
                                                    {{ topic.question }}
                                                </span>
                                            </div>
                                            <div class="text-ellipsis timestamp-container">
                                                <span class="timestamp">
                                                    {{ topic.timestamp | date:'dd-MM-yyyy HH:mm' }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </a>                                
                            </button>
                        </ng-container>
                    </span>
                </div> 
            </div>
        </div>
    </div>
</div> 



<div *ngIf="isLoading" class="loader-container">
    <div class="circles">
        <div *ngFor="let color of ['primary', 'primary', 'primary', 'primary', 'alternate', 'alternate', 'alternate', 'alternate']" [ngClass]="color"></div>
    </div>
    <div class="loading-text">{{ currentText }}</div>
</div>




