<mat-toolbar class="header">
  <a routerLink=""> <img src="assets/images/InnoBoon-Logo.png" class="logo" alt="Innobbon" /></a>
  <span class="example-spacer"></span>

  <button class="home-icon" mat-mini-fab routerLink="/features/dashboard">
    <mat-icon >home</mat-icon>
 </button>

  <button class="home-icon" mat-mini-fab [matMenuTriggerFor]="menu">
    <mat-icon>person</mat-icon>
 </button>

  <mat-menu #menu="matMenu">
    <div class="p-name-container">
      <span class="p-name">{{userName}}</span>
    </div>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>

</mat-toolbar>