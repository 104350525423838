import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  hideIndustry : boolean = true;
  hideAnalysis : boolean = true;
  methodAnalysis : boolean = true;
  sharedData: any;
  searchQuery: string = '';
  

   data = [
    { id: 1, name: 'Generate Requirement' , logo : "assets/images/Dashboard_images/gen.png" ,route:'../gen-req' ,description:'Convert natural language inputs into formal requirements specifications'},
    { id: 2, name: 'Review Requirements' , logo : "assets/images/Dashboard_images/documentation.png"  ,route:'../rev-req', description:'Review and improve ambiguous, incomplete, incorrect, illogically formatted requirements using semantics, grammar, plausibility, consistency and context checks'},
    { id: 3, name: 'Classify Requirements' , logo : "assets/images/Dashboard_images/classify.png"  ,route:'../class-req',description:' Classify requirements into different categories (e.g. SW, Calibration, Functional, Non-Functional, Design Decision, etc.)'},
    { id: 4, name: 'Group Requirements' , logo : "assets/images/Dashboard_images/grouping.png" ,description:'Group similar requirements together (e.g. vehicle or SW feature based grouping)'}, 
    { id: 5, name: 'Generate Verification Criteria'  , logo : "assets/images/Dashboard_images/content-marketing.png"  ,route:'',description:'Generate verification criteria for individual requirements as per pre-defined template'}, 
    { id: 6, name: 'Summarize Requirements and Q&A'  , logo : "assets/images/Dashboard_images/qa.png"  , route:'',description:'Provide a brief summary of the requirements and provide answers for user specific question'},
  ];

  constructor (
    private router:Router,
    
  ){

  }

  ngOnInit() {
    // this.recievedData()
  }

 

  filterData(): any[] {
    return this.data.filter(item =>
      item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  chunkData(arr: any[], chunkSize: number): any[][] {
    let result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
    }
    return result;
}
}
