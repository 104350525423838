import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFIle, IUploadConfig } from 'src/app/core/utils/interface/shared.interface';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  files: any [] = [];
  showWarningPopup: boolean = false
  loading: boolean;
  isError:boolean = false;
  uploadConfig: IUploadConfig = {
    accept: '.txt,.pdf,.docs,.docx',
    isMultiple: false
  }
  constructor(
    private dialogref: MatDialogRef<UploadComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
     
  }

  ngOnInit(): void {

  }

  uploadFileNew(event: any) {   
    if (event.target.files.length > 1) {
      for (let file of event.target.files) {
        this.files.push(this.onCreateFileObj(file));
      }
    } else {
      const fileList: FileList = event.target.files;
      let file = fileList[0];
      this.files.push(this.onCreateFileObj(file));
    }
    event.target.value = null;
  }

  onCreateFileObj(file: any) {
    const fileSizeInKB = file.size / 1024;
    const fileSize = fileSizeInKB >= 1024 ? `${(fileSizeInKB / 1024).toFixed(2)}MB` : `${fileSizeInKB.toFixed(2)}KB`;
    let set = <IFIle>{}
    set.file= file
    set.fName =file.name
    set.fSize =fileSize
    return set;
  }

  removeSelectedFile(index?: any) {
    this.files.splice(index, 1);
  }

  getLoading(){
    return this.loading;
  }

  onClose() {
    this.dialogref.close(null);
  }

  onCloseError() {
    this.isError = false;
  }

  
  onUpload() {
    if (this.files.length > 0) {
      const formData = new FormData();
      for (let item of this.files) {
        formData.append('file', item.file);
        // formData.append('fileTypes', item.file.type);
      }
      this.dialogref.close(formData);
    } else {
      this.isError = true;
    }
  } 



}
