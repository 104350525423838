
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexPlotOptions,
    ApexResponsive,
    ApexXAxis,
    ApexLegend,
    ApexFill,
    ApexYAxis,
    ApexTitleSubtitle,
    ApexOptions
  } from "ng-apexcharts";

  export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    title?: ApexTitleSubtitle;
    subtitle?: ApexTitleSubtitle;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    responsive: ApexResponsive[];
    xaxis: ApexXAxis;
    legend: ApexLegend;
    fill: ApexFill;
    yaxis: ApexYAxis,
  };
  
export const overallscore = <ApexOptions>{
  series: [60],
  chart: {
    height: 150,
    type: "radialBar",
    toolbar: {
      show: false,
      tools: {
        download: false,
    },
    title: {
      text: '', // Replace this with your desired title
      align: 'center',
      margin: 0,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Arial, sans-serif',
        color: '#333', // Title text color
      }
    }
  }
  },
 
  plotOptions: {
    radialBar: {
      startAngle: -180,
      endAngle: 180,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },

      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: false,
          color: "#888",
          fontSize: "17px"
        },
        value: {
          formatter: function(val) {
            return val.toFixed(0) + "%"; // This line appends the "%" after the value
          },
          color: "#111",
          fontSize: "25px",
          show: true,
          offsetY: 10,
          offsetX: 5,
        }
      }
    },
    
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#ABE5A1"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  stroke: {
    lineCap: "round"
  },
  labels: []
};



export const overAllpercentage = <ApexOptions>{
  series: [
    {
      name: "",
      data: []
    },
  ],
  chart: {
    type: "bar",
    height: 140,
    stacked: false,
    stackType: "100%",
    toolbar: {
      show: false,
      tools: {
        download: false,
      }
    }
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: 'center',
      },
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val + " %";
    },
    offsetY: 0,
    style: {
      fontSize: '12px',
      colors: ["white"]
    }
  },
  stroke: {
    width: 1,
    colors: ["#fff"]
  },
  title: {
    text: ""
  },
  xaxis: {
    categories: ['Semantics', 'Grammer', 'Plasuibility', 'Consistency', 'Context'],
    labels: {
      show: false , // Hide x-axis labels
      style: {
        fontSize: '12px', // Adjust font size if needed
        fontWeight: 'bold' // Make labels bold
      }
    },
  },
  yaxis: {
    labels: {
      formatter: function(val) {
        return val + "%"; // Format y-axis labels as percentages
      },
      style: {
        fontSize: '12px', // Adjust font size if needed
        fontWeight: 'bold' // Make labels bold
      }
    }
  },
  tooltip: {
    enabled: false,
    y: {
      formatter: function(val) {
        return val + "K";
      }
    }
  },
  fill: {
    opacity: 1
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: 40
  }

};



