import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-generate-requirements',
  templateUrl: './generate-requirements.component.html',
  styleUrls: ['./generate-requirements.component.scss']
})
export class GenerateRequirementsComponent implements OnInit{
  form: FormGroup;
  userStory : any = [ { id:1 ,name:'Brief Requirements', value:'Brief'} , {id:2 ,name:'Detailed Requirements', value:'Detailed'}]
  historyNav : boolean = false;
  isLoading : boolean = false;
  data :any =[];
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts : string [] = [];
  topics: any[] = [];
  historyDetails: any = null;
  constructor(
    private router: Router,
    private apiService : ApiService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
  ) { } 


  ngOnInit(): void {
    this.texts = ["Requirement generation in progress","Please wait","Almost finished"];
    this.createForm();
    this.loadTopics();
  }


  createForm() {
    this.form = this._fb.group({
      reqInput: ['',Validators.required],
      includeVerify:[false,Validators.required,],
      userStory: ['Brief',Validators.required],
    })
  }


 
  copyText() {
    let textToCopy = '';
    this.data.forEach(item => {
      textToCopy += `${item.name}\n${item.value}\n\n`;
    });
    textToCopy = textToCopy.trim();
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastr.success('Successfully Requirement Copied.');

  } 


  onGenerate(){
    let set = this.getRawValue();
    this.isLoading = true;
    this.startTextAnimation();
    this.apiService.apiRequestPost('ai_services/generate_requirement',null,set).subscribe((data: any) => {
      if (data) {
        this.data = data.output;
        this.isLoading = false;
        this.loadTopics();
        this.toastr.success('Success !', 'Successfully Requirement Generated.');
      }
    },
      err => {
        this.isLoading = false;
        this.toastr.error('We are unable to process your request. Please try later');
      }
    );
  } 

  loadTopics() {
    const queryParam = '?requirement_type=Generate';
    this.apiService.apiRequestGet('entity_service/topics', queryParam).subscribe(data => {
        if (data && data['topics']) {
          this.topics = data['topics'].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        }
    });
  }

  getRawValue() {
    let item = this.form.getRawValue();
    const set = {
      question: item.reqInput,
      requirement_format: item.userStory,
      verification_criteria: item.includeVerify
    }
    return set;
  } 

  onHistory(){
  this.historyNav = !this.historyNav
  } 

  value:any;
  onLoadHistory(topicId: number){
    this.texts = ["Please wait"];
    this.isLoading=true;
    const queryParam = `?topic_id=${topicId}`;
    this.apiService.apiRequestGet('entity_service/history', queryParam).subscribe((data: any) => {
      if (data && data.output) {
        this.isLoading = false;
        const questionData = data.output.question;
        this.form.controls['reqInput'].setValue(questionData.question)
        this.form.controls['userStory'].setValue(questionData.requirement_format)
        this.form.controls['includeVerify'].setValue(questionData.verification_criteria)
        this.data = data.output.ai_output;
        this.cdr.detectChanges();
      }
    },
    err => {
      this.isLoading = false;
      this.toastr.error('We are unable to process your request. Please try later');
    });
  }
  


  onClear(){
    this.data = [];
    this.form.reset();
    this.form.controls['userStory'].setValue('Brief')
  }


  startTextAnimation() {
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 5000);
  }

  changeText() {
    this.currentText = this.texts[this.index];
    this.index = (this.index + 1) % this.texts.length;
    if (this.index === 0) {
      clearInterval(this.intervalId);
    }
  }
}
